<template>
    <div class="context-background" v-if="!close">
        <div class="message-context" v-click-outside="closeContext">
            <h3>{{ message }}</h3>
            <div class="context__new-password">
                <input 
                    :type="inputType" 
                    @focus="valid = true"
                    :placeholder="placeholderText" 
                    v-model="value" 
                    :class="{'validation-faild': !valid}" 
                    ref="input"
                />
            </div>
            <div class="context__save">
                <Button @click="save">
                    Cохранить
                </Button>
            </div>
        </div>
    </div>
</template>
<script>
import Button from '@/components/ui/button/Button.vue';
import Notify from '@/services/helpers/notify';
export default {
    props: ['placeholderText', 'inputValue', 'message', 'inputType'],
    components: { Button },
    data(){
        return{
            value: '',
            close: false,
            valid: true,
        }
    },
    mounted(){
        const inputRef = this.$refs.input
        this.$nextTick(() => 
        {
            inputRef.focus();
            this.value = this.inputValue
        })
    },
    methods: 
    {
        validateValue()
        {
            if(this.value === null || this.value === '' || this.value === undefined)
            {
                Notify.error('Форма пуста')
                this.valid = false;
                return false;
            }
            if(this.value.length < 8 && this.inputType === 'password')
            {
                Notify.error('Длина пароля не превышает 8 символово')
                this.valid = false;
                return false;
            }
            this.valid = true;
            return true
        },
        closeContext()
        {
            this.close = true;
            this.$emit('closed');
            this.value = null;
            this.valid = true
        },
        save()
        {
            if(this.validateValue())
            {
                this.$emit('save', this.value);
                this.value = null;
            }
        },
    },
}
</script>
<style lang="scss">
.context-background
{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 999999;
    .message-context
    {
        position: absolute;
        background: #191919;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.7);
        border-radius: 8px;
        width: 426px;
        text-align: center;
        font-family: $font-family;
        h3
        {
            font-style: normal;
            font-weight: 300;
            font-size: 24px;
            line-height: 144%;
            text-align: center;
            color: rgba(255, 255, 255, 0.8);
        }
        .context__save
        {
            margin-bottom: 15px;
            button
            {
                border-radius: 16px;
                padding: 8px 18px;
                font-weight: 600;
                font-size: 12px;
            }
        }
        .context__new-password
        {
            margin: 0 34px 25px 34px;
            input
            {
                outline: none;
                color: #fff;
                width: 75%;
                background: none;
                border: 0.7px solid rgba(255, 255, 255, 0.15);
                border-radius: 8px;
                padding: 12px 33px;
            }
            input::placeholder
            {
                text-align: center;
            }
        }
    }
}
.context-background .message-context .context__new-password .validation-faild::placeholder
{
    color: #ee5c5c;
}
.context-background .message-context .context__new-password .validation-faild
{
    border: 0.4px solid #ee5c5c;
}
</style>